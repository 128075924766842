
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Cookies from 'js-cookie';
import type { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import App from 'next/app';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import SharedProvider from '@invideoio/web-shared/context/SharedProvider/SharedProvider';
import { COOKIE_KEYS, URL_SEARCH_PARAM, } from '@invideoio/web-shared/utils/utils.constant';
import { fetchConfig } from '@lib/API';
import ContextProvider from '@store/store';
import ErrorBoundary from '@components/ErrorBoundary';
import { FeatureConfig } from '@invideoio/web-shared/types/Config';
import '@styles/globals.scss';
import { fetchFeatureJson } from '@utils/experiments/utils';
import { localStorageWithExpiry } from '@utils/localstorage.utils';
import { ConfigType } from '../types';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { Inter } from 'next/font/google';
// If loading a variable font, you don't need to specify the font weight
const inter = Inter({
    subsets: ['latin'],
    weight: ['300', '400', '500', '600', '700', '800'],
    display: 'swap',
});
const Error404Page = dynamic(() => import('./404'), {
    loading: () => <div>Loading...</div>,
    ssr: false,
});
const LoadAnalytics = dynamic(() => import('@components/analytics/LoadAnalytics'), {
    loading: () => <div>Loading...</div>,
    ssr: false,
});
export function reportWebVitals(metric: NextWebVitalsMetric) {
    if (metric.label === 'web-vital') {
        console.log(`${metric.name}: ${metric.value.toFixed(2)}`);
    }
}
interface RootProps extends AppProps {
    config: ConfigType;
    featureConfig: FeatureConfig;
    platform: PlatformType;
    userAgent: string;
    isGoogleBot: boolean;
}
interface MyWindow extends Window {
    nua?: string;
}
const RootApp = ({ Component, pageProps, ...otherProps }: RootProps) => {
    useEffect(() => {
        (window as unknown as MyWindow).nua = otherProps.userAgent;
        /**
         * Need to store affiliate ref from query params in cookie for trackability.
         * Will change this with cookie service.
         **/
        const search_params = window.location.search;
        // Store Params for Studio
        if (search_params !== '' &&
            (search_params.includes(URL_SEARCH_PARAM.REF) ||
                search_params.includes(URL_SEARCH_PARAM.SS_CID) ||
                search_params.includes(URL_SEARCH_PARAM.FB_CL_ID) ||
                search_params.includes(URL_SEARCH_PARAM.UTM_SOURCE) ||
                search_params.includes(URL_SEARCH_PARAM.GCL_ID) ||
                search_params.includes(URL_SEARCH_PARAM.IR_CLICKED) ||
                search_params.includes(URL_SEARCH_PARAM.MP_ID) ||
                search_params.includes(URL_SEARCH_PARAM.REFERRER) ||
                search_params.includes(URL_SEARCH_PARAM.MS_CLK_ID) ||
                search_params.includes(URL_SEARCH_PARAM.USER_REFERRER))) {
            // Need to do this here since its replicated on all other repos in a similar way.
            const nextYear = new Date(new Date().getTime() + 86400 * 365 * 1000);
            Cookies.set(window.btoa(COOKIE_KEYS.QUERY_PARAMS), window.location.search, {
                expires: nextYear,
                path: '/',
                secure: true,
                sameSite: 'strict',
                domain: '.invideo.io',
            });
        }
        const params = new RegExp(Object.values(URL_SEARCH_PARAM).join('|'));
        if (search_params !== '' && params.test(search_params)) {
            localStorageWithExpiry.set('qs', search_params, 60 * 24);
        }
        const domain_referrer = document.referrer;
        if (domain_referrer !== '' &&
            !domain_referrer.match(/invideo\.io|localhost/)) {
            localStorageWithExpiry.set('domain_referrer', domain_referrer, 60 * 24);
        }
    }, []);
    return (<SharedProvider config={{
            RECAPTCHA_KEY: process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_KEY || '',
            siteConfig: otherProps.config,
            API: {
                ANALYTICS_API: process.env.NEXT_PUBLIC_ANALYTICS_API_URL || '',
                AUTH_API: process.env.NEXT_PUBLIC_AUTH_API || '',
                CORE_API: process.env.NEXT_PUBLIC_API_URL || '',
                MEDIA_API: process.env.NEXT_PUBLIC_MEDIA_API || '',
            },
            APP_ENV: process.env.NEXT_PUBLIC_ENV || 'production',
            CDN_URL: {
                SHARED_URL: process.env.NEXT_PUBLIC_SHARED_CDN_URL || '',
                LIHP_URL: process.env.NEXT_PUBLIC_LIHP_CDN_URL || '',
                EDITOR_URL: process.env.NEXT_PUBLIC_EDITOR_CDN_URL || '',
            },
            HOST_URL: process.env.NEXT_PUBLIC_BASE_HOST || '',
            CREATE_PAGE_HOST_URL: process.env.NEXT_PUBLIC_CREATE_PAGE_HOST || '',
            IMAGE_KIT: {
                IMAGEKIT_PUBLIC_KEY: process.env.NEXT_PUBLIC_IMAGEKIT_PUBLIC_KEY,
                IMAGEKIT_URL_ENDPOINT: process.env.NEXT_PUBLIC_IMAGEKIT_URL_ENDPOINT,
            },
        }} user={null} featureConfig={otherProps.featureConfig}>
      <ContextProvider initialState={otherProps as any}>
        {(pageProps.notFound && <Error404Page />) || (<main className={inter.className}>
            <Component {...pageProps}/>
          </main>)}
        <ErrorBoundary>
          <LoadAnalytics userData={null} platform={otherProps.platform} isGoogleBot={otherProps.isGoogleBot}/>
        </ErrorBoundary>
      </ContextProvider>
    </SharedProvider>);
};
RootApp.getInitialProps = async (appContext: AppContext): Promise<any> => {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const deviceDetect = require('../middleware/deviceDetect').default;
    const appProps = await App.getInitialProps(appContext);
    try {
        const { os, platform, browser, userAgent, isGoogleBot } = await deviceDetect(appContext.ctx);
        const config = await fetchConfig();
        const featureConfig = await fetchFeatureJson();
        const socialLinks = config.social;
        return {
            ...appProps,
            os,
            platform,
            browser,
            userAgent,
            isGoogleBot,
            socialLinks,
            config,
            featureConfig,
        };
    }
    catch (error) {
        console.error(error);
        return { ...appProps };
    }
};
const __Next_Translate__Page__194b7d23095__ = RootApp;

    export default __appWithI18n(__Next_Translate__Page__194b7d23095__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  